@import './code-input';

.enter-code {
  &__error-w {
    height: 16px;
    height: 15px;
    font-size: 12px;
    font-weight: 700;
    color: red;
    margin: 20px 0;
  }

  &__display {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1;
    text-align: center;
    margin: 0 auto;

    @include mobile-layout {
      width: 100%;
      margin: 20px auto;
      background-color: $c-gray-lighter;
    }

    &-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
    }

    &-illustration {
      width: 100%;
      height: 300px;
      border-bottom: 1px solid $c-gray-light;
      background: url(../../../images/backgrounds/welcome/hc-illustration-1.png)
        no-repeat;
      background-size: contain;
      background-position: center;
      background-color: #e6e6e6;

      @include mobile-layout {
        border: none;
        width: 100%;
        background: url(../../../images/backgrounds/entercode/enter-code.png)
          no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 350px) {
        height: 200px;
      }

      &-sm {
        height: 340px;
        background-position: center bottom;
        background-size: 75% a;
      }
    }

    &-title {
      color: $c-blue;
      font-size: 36px;
      font-weight: 500;
      line-height: 44px;
      margin: 40px auto 0px;
      padding: 0;

      @include mobile-layout {
        line-height: 1;
        font-size: 30px;
        margin: 0 auto;
      }

      @media screen and (max-width: 350px) {
        font-size: 24px;
      }

      &-sm {
        font-size: 20px;
      }
    }

    &-copy {
      line-height: 21px;
      font-size: 16px;
      padding: 10px 5px 30px 5px;

      @include mobile-layout {
        font-size: 16px;
        line-height: 1.3;
        padding: 0px 5px 0px 5px;
      }
    }
  }
}
