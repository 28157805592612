.header {
  &__mobile-bottom {
    z-index: 900;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    font-size: 16px;
    line-height: 1;
    border-top: 1px solid #d2d2d2;
  }
}
