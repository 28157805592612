.five-card-arranger {
  &__w {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 45px 20px;
    margin-right: 0;
    width: 240px;
    border-left: 1px solid #d2d2d2;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25);
  }

  &__card {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 90px;
    width: 200px;
    border-radius: 6px;
    color: #c2c1c1;
    font-size: 32px;
    border: 0 dashed #d2d2d2;
    transition: all 300ms;

    &--active {
      background-color: #f4f4f4;
      border: 1px dashed #d2d2d2;
      min-height: 120px;
      margin-top: 9px;
    }

    &--margin-top {
      margin-top: 40px;
    }
  }
}
