@import './slider';
@import './slider-controls';
@import './slide';
@import './dot-group';

.gif_player {
  display: inline-block;
  position: relative;
  user-select: none;
  overflow: hidden;
  height: 370px;
  width: 100%;
  @media (max-width: 750px) {
    height: 100%;
  }
}
.gif_player .play_button {
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px dashed #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 24px;
  left: 50%;
  opacity: 1;
  padding: 14px 10px;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -45%) scale(1) rotate(0deg);
  transition: transform 0.4s, opacity 0.4s;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gif_player .play_button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.7);
}
.gif_player .play_button::after {
  content: 'Play';
}

.gif_player.playing {
  pointer-events: none;
}
.gif_player.playing .play_button {
  transform: translate(-50%, -50%) scale(0) rotate(180deg);
  opacity: 0.5;
}
.gif_player img {
  max-width: 440px;
  width: 100%;

  @media (max-width: 750px) {
    width: 100%;
  }

  @media (max-width: 540px) {
    width: 65%;
  }
  @media (max-width: 414px) {
    width: 95%;
  }

  @media (max-width: 320px) {
    width: 75%;
  }
}
