.instructions-carousel {
  &__slider-controls {
    &__btn--next {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: inherit;
      font-size: 14px;
      font-family: inherit;
      font-weight: $font-w-light;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      text-align: center;
      text-decoration: none;
      padding: 9px 17px;
      margin: 0;
      transition: background-color 300ms;
      color: white;
      background-color: #255eba;
      border-radius: 4px;
      border: none;
      @include mobile-layout {
        margin-bottom: 125px;
      }
    }
  }
}
