.code-input {
  &__w {
    width: 100%;
    position: relative;
  }

  &__input {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 10px;
    cursor: pointer;
    height: 40px;
    width: 155px;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    background-color: white;
    font-size: 21px;
    transition: border 300ms;
  }
}
