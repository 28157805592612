// Base Colors
$c-gray-light: #d2d2d2;
$c-gray-lighter: #f2f2f2;
$c-nero: #231f20;
$c-gray: #8a8888;
$c-white-smoke: #f5f5f5;
$c-gray-lightest: #e5e5e5;
$c-white: #ffffff;
$c-purple: #8b2b6e;
$c-red-bright: #f12938;
$c-red-dark: #b21e28;
$c-orange-dark: #a66010;
$c-orange: #f49500;
$c-orange-lightest: #f6d6ae;
$c-yellow: #ffbe1a;
$c-yellow-light: #fff0b5;

$c-blue: #255eba;
$c-blue-light: #0068ae;
$c-blue-lightest: #f2f5fa;
$c-blue-ligher: #a6c9de;
$c-blue-dark: #083d77;
$c-green: #8ccc33;
$c-green-light: #a7d500;
$c-green-lighter: #a2d134;
$c-green-lightest: #d1e8c7;
$c-charcoal: #444444;
$c-crimson: #d9bdd0;

// Money Mind
$c-happiness: $c-orange;
$c-commitment: $c-blue-light;
$c-protection: $c-green-light;

// Funded Score
$c-underfunded: $c-red-dark;
$c-overfunded: $c-orange;
$c-ideal: $c-green-light;

// Asset Allocation
$c-stocks: $c-blue;
$c-bonds: $c-yellow;
$c-other: $c-white-smoke;

// Net Worth
$c-liabilities: $c-yellow;
$c-assets: $c-green;

// Status
$c-status-good: $c-green;
$c-status-medium: $c-yellow;
$c-status-bad: $c-red-dark;
$c-status-na: $c-gray;

// Misc
$c-active-btn: $c-blue;
$c-active-border: $c-blue;
$c-active-state-notifications: $c-blue-lightest;
$c-background-hover: $c-white-smoke;
$c-brand: $c-blue;
$c-header: $c-blue;
$c-warning: $c-red-bright;
$c-current-allocation: $c-purple;
$c-notification-badge: $c-red-bright;
$c-table-header: $c-white-smoke;
$c-icons-disabled: $c-gray-lightest;
$c-icons-enabled: $c-gray-light;
$c-dividers: $c-gray-lightest;
$c-borders: $c-gray-light;
$c-icons: $c-gray;
$c-text-running: $c-gray;
$c-titles: $c-nero;
$c-start-btn: $c-gray-lighter;
$c-tooltip: #444444;
