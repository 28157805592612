.instructions-carousel {
  &__slider {
    margin-bottom: 20px;
    @include mobile-layout {
      background-color: $c-white-smoke;
      max-width: 80%;
      margin: 0 auto;
    }
  }
}
