@import './five-card-mobile-table';

.five-card-table {
  display: flex;
  position: absolute;
  top: 44px;
  left: 44px;

  &__drop-down {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    color: #255eba;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 20px;
    opacity: 1;
    transition: opacity 300ms;

    &:hover {
      text-decoration: underline;
    }

    &--hide {
      opacity: 0;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 10px;
      width: 2px;
      border-radius: 3px;
      background-color: #255eba;
      left: 100px;
      bottom: 5px;
      transform: rotate(60deg);
      transition: transform 500ms, bottom 250ms;
    }

    &:hover::before,
    &:hover::after {
      bottom: 0px;
    }

    &::after {
      content: '';
      left: 93px;
      transform: rotate(-60deg);
    }

    .five-card-table__table-w--open &:hover::before,
    .five-card-table__table-w--open &:hover::after {
      bottom: 10px;
    }

    .five-card-table__table-w--open &::before {
      transform: rotate(-60deg);
    }

    .five-card-table__table-w--open &::after {
      transform: rotate(60deg);
    }
  }

  &__table-w {
    min-width: 200px;

    > div {
      // Droppable area on table
      width: 100%;
      height: 160px;
      transition: height 300ms;
    }

    &--open {
      > div {
        height: 550px;
      }
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
