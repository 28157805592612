.welcome {
  &__w {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //Iphone 5/SE styles
    .disclosure__w {
      @media (max-height: 568px) {
        padding-bottom: 15px;
      }
    }
  }
  &__display {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1;
    text-align: center;
    margin: 0 auto 20px;

    @include mobile {
      padding-bottom: 10px;
      margin: 0 auto;
      padding-top: 20px;
      background: #f5f5f5;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      &-center {
        justify-content: center;
        flex-grow: 0;
      }

      &-block {
        font-size: 12px;
        padding: 0px 10px 0px 10px;
      }

      @include mobile {
        padding: 0 16px;
      }
    }

    &-illustration {
      width: 100%;
      height: 417px;
      border-bottom: 1px solid $c-gray-light;
      background: url(../../../images/backgrounds/welcome/hc-illustration-1.png)
        no-repeat 0 0;
      background-size: contain;
      background-position: center;
      background-color: $c-gray-lighter;

      &-sm {
        height: 340px;
        background-position: center bottom;
        background-size: 75% auto;
      }

      @include mobile {
        height: 45vw;
        margin-bottom: 30px;
        border-bottom: none;
      }
    }

    &-title {
      color: $c-blue;
      font-size: 36px;
      font-weight: 500;
      line-height: 44px;
      margin: 25px auto 25px;

      &-sm {
        font-size: 20px;
        margin: 30px auto 10px;
      }

      @include mobile {
        font-size: 30px;
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
      }
    }

    &-copy {
      max-width: 524px;
      line-height: 21px;
      font-size: 16px;

      &-link {
        display: block;
        width: 100%;

        span {
          cursor: pointer;
          color: $c-blue;
        }
      }
    }

    &-divider {
      border-left: solid 2px #d2d2d2;
      height: 30px;
      margin: 35px 0px 0 0px;
    }

    &-start {
      line-height: 21px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;

      &-name {
        background-color: $c-gray-lighter;
        border-radius: 5px;
      }

      &-code {
        padding-top: 10px;
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        color: $c-blue;
      }
    }
  }

  &__carousel {
    width: 100%;
    max-height: 500px;
    margin: 0 auto;

    .carousel__slide {
      padding: 0 !important;
      min-height: 300px;
    }

    &-item {
      width: 100%;
      min-height: 200px;
    }

    &-copy {
      margin-top: 30px;
    }

    &-dot {
      border: none;
      background-color: transparent;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      padding: 0;

      &.carousel__dot--selected {
        > span {
          background-color: #255eba;
        }
      }

      > span {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #d2d2d2;
      }
    }
  }
}
