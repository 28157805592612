.fifteen-card-holder {
  &__w {
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 165px;
    border-top: 1px solid #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      top: 49px;
      bottom: auto;
      width: 100%;
      min-height: calc(100% - 49px);
      padding-bottom: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      border-top: none;
      padding-top: 0;
    }
    // Iphone 5/SE styles
    @media (max-height: 568px) {
      min-height: calc(100% + 40px);
    }
  }

  &__card-w {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;

    @include mobile {
      width: auto;
      height: 450px;
      margin: 20px;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  &__item {
    width: 202px;
    height: 122px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    border-radius: 6px;
    z-index: 0;
    transition: background-color 300ms, border 300ms;

    &::after,
    &::before {
      content: '';
      display: flex;
      position: absolute;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom: 0;
      opacity: 1;
      transition: height 300ms, opacity 200ms;
    }
    &::after {
      top: 119px;
      width: 185px;
      height: 0;
      z-index: -1;
    }
    &::before {
      top: 119px;
      width: 170px;
      height: 0;
      z-index: -2;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    @include mobile {
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    img {
      max-width: 40px;
    }

    span {
      margin-top: 15px;
      line-height: 1;
      font-weight: 500;
    }

    &-check {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }

    &--stack {
      cursor: pointer;
      position: relative;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid transparent !important;
      z-index: 500;

      &::after,
      &::before {
        content: '';
        display: flex;
        position: absolute;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
        opacity: 0;
        transition: height 300ms, opacity 200ms;
      }
      &::after {
        top: 120px;
        width: 185px;
        height: 0;
        z-index: -1;
      }
      &::before {
        top: 120px;
        width: 170px;
        height: 0;
        z-index: -2;
      }

      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;

        &::after {
          opacity: 1;
          height: 5px;
        }
        &::before {
          opacity: 1;
          height: 10px;
        }
      }

      @media (max-width: 750px) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;

        &::after {
          opacity: 1;
          height: 5px;
        }
        &::before {
          opacity: 1;
          height: 10px;
        }
      }
    }

    &--complete {
      border-bottom: none !important;
      opacity: 0.4;

      &::after {
        content: '';
        display: none;
      }
      &::before {
        content: '';
        display: none;
      }

      &:hover {
        border-bottom: none !important;
      }
    }
  }

  &__protection {
    border-color: #a2d134;
    color: #a2d134;

    &::before,
    &::after {
      background-color: #a2d134;
    }

    &.fifteen-card-holder__item--stack {
      color: white;
      background-color: #a2d134;

      &::before,
      &::after {
        background-color: #a2d134;
      }
    }
  }
  &__commitment {
    border-color: #0068ae;
    color: #0068ae;

    &::before,
    &::after {
      background-color: #0068ae;
    }

    &.fifteen-card-holder__item--stack {
      color: white;
      background-color: #0068ae;

      &::before,
      &::after {
        background-color: #0068ae;
      }
    }
  }
  &__happiness {
    border-color: #f49500;
    color: #f49500;

    &::before,
    &::after {
      background-color: #f49500;
    }

    &.fifteen-card-holder__item--stack {
      color: white;
      background-color: #f49500;

      &::before,
      &::after {
        background-color: #f49500;
      }
    }
  }

  &__check-icon {
    position: absolute;
    left: 9px;
    bottom: 9px;
  }

  &__loading-tile-w {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__loading-icon {
    margin-bottom: 24px;
    animation: rotation 2s infinite linear;
  }

  &__loading-text {
    font-size: 18px;
    color: #22263f;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
