.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: $modal-zindex;
    background-color: rgba(0, 0, 0, 0.6);
  }
  &__content-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 0;
    padding: 25px;
    background: $c-white;
    border-radius: 5px;
    &--center {
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @include mobile-layout {
      min-width: 100%;
      height: 100vh;
      padding: 0;
      border-radius: 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-size: 14px;
    font-weight: $font-w-bold;
    &--center {
      justify-content: center;
    }
    @include mobile-layout {
      padding: 0;
    }
  }
}
