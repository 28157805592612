html {
  height: 100%;
  width: 100%;
}

body {
  font-family: $font-main;
  font-weight: $font-w-light;
  position: relative;
  background-color: #f4f4f4;
  height: 100%;
}

#root {
  height: 100%;
  position: relative;
}
