@mixin mobile {
  @media ($media-small) {
    @content;
  }
}

@mixin tablet {
  @media ($media-medium) {
    @content;
  }
}
