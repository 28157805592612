.instructions-carousel {
  @include mobile-layout {
    min-height: 100vh;
    background-color: whitesmoke;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  @media (max-width: 414px) {
    width: 100%;
    box-sizing: border-box;
  }

  &__slide {
    height: 100%;
    @media (min-width: 414px) {
      width: 100%;
    }
    &-img {
      width: 100%;
      @include mobile-layout {
        max-width: 250px;
      }
    }
  }
}
