@import './fifteen-card-holder';
@import './fifteen-card-table';

.personal-priorities {
  &__w {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;

    @include mobile-layout {
      position: absolute;
      margin: 0 15px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      margin: 0;
    }
  }
  &__header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
