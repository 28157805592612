.transform {
  &__rotate {
    &-180 {
      transform: rotate(180deg);
    }
  }
}

@mixin mobile-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}

@mixin desktop-layout {
  @media screen and (min-width: 751px) {
    @content;
  }
}

@mixin mobileCardDeckHeight {
  // M Phones
  @media (max-width: 550px) {
    min-height: 545px;
  }

  // XL Phones
  @media (max-width: 550px) and (min-height: 810px) {
    min-height: 725px;
  }

  // S Phones
  @media (max-width: 320px) {
    min-height: 470px;
  }
}
