.instructions-carousel {
  .carousel {
    width: 100%;
    max-height: 500px;
    margin: 0 auto;
    &__dot-group {
      @include mobile-layout {
        margin: 0;
      }
    }
    &__dot {
      border: none;
      background-color: transparent;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      > span {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $c-gray-light;
      }
      &--selected {
        > span {
          background-color: $c-gray;
        }
      }
    }
  }
}
