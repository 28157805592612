.five-card-mobile-table {
  &__w {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: absolute;
    top: 49px;
    right: 0;
    left: 0;
    background-color: #fff;
  }

  &__card-w {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 550px;
    margin: 20px;
    overflow: hidden;
    @media (max-width: 420px) {
      height: 470px;
    }
  }

  &__cards-w {
    position: relative;
    z-index: 0;
    display: block;
    min-height: 140px;
    width: 200px;
    margin-bottom: 30px;
    @media screen and (max-width: 420px) {
      margin-bottom: 0px;
    }
  }

  &__card {
    cursor: pointer;
    position: relative;
    height: 120px;
    width: 200px;
    top: 0;
    transition: top 800ms ease-in, margin-bottom 300ms;

    &--active {
      z-index: 800;
      position: absolute;
      top: 700px;
    }

    @media (max-width: 420px) {
      // Actual Card
      > div {
        height: 100px !important;
      }
    }
  }

  &__toggle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    padding-right: 1px;
    background-color: #fff;
    border-radius: 50%;
    bottom: 10px;
    left: 10px;

    @media (max-width: 420px) {
      bottom: 30px;
    }

    &--protection {
      border: 1px solid #8eb600;

      &-active {
        &::after {
          content: url(../../../images/icons/check_protection.png);
          position: absolute;
          top: 3px;
          left: 3px;
        }
      }
    }

    &--commitment {
      border: 1px solid #0d5c98;

      &-active {
        &::after {
          content: url(../../../images/icons/check_commitment.png);
        }
      }
    }

    &--happiness {
      border: 1px solid #d48200;

      &-active {
        &::after {
          content: url(../../../images/icons/check_happiness.png);
        }
      }
    }
  }

  &__pick-w {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 300ms;

    &--open {
      top: 0;
      z-index: 800;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  &__pick {
    max-width: 140px;
    display: block;
    cursor: pointer;
    font-size: 12px;
    color: #255eba;
    width: 100%;
    position: absolute;
    top: 0px;
    text-align: center;
    padding-top: 20px;
    z-index: 900;

    &:hover {
      text-decoration: underline;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 20px;
      width: 2px;
      border-radius: 3px;
      background-color: #255eba;
      top: 0;
      left: 42%;
      transform: rotate(60deg);
      transition: all 500ms;
    }

    &::after {
      content: '';
      left: 54%;
      transform: rotate(-60deg);
    }

    &--open {
      top: 20px;
    }

    &--open::before {
      transform: rotate(-60deg);
    }

    &--open::after {
      transform: rotate(60deg);
    }

    &-list {
      opacity: 0;
      transition: all 250ms;

      &--show {
        opacity: 1;

        > div > div > div {
          height: 100px !important;
        }
      }
    }
  }

  &__pick-remove {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      left: 15px;
      height: 13px;
      width: 3px;
      border-radius: 5px;
      transform: rotate(45deg);
      background-color: #fff;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
