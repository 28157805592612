.tile {
  position: relative;

  &__btn-back {
    color: #255eba !important;

    > img {
      position: relative;
      margin-right: 3px;
      left: 0;
      transition: left 300ms;
    }

    &:hover {
      background-color: white !important;
      > img {
        left: -5px;
      }
    }
  }
}
