.fifteen-card-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 240px;
  bottom: 165px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    height: 450px;
    padding-top: 50px;
    top: 50px;
    right: 0;
    left: 0;
  }

  @media (min-width: 420px) and (max-width: 750px) {
    padding-top: 100px !important;
  }
  &__w {
    text-align: center;
    @include mobile {
      display: flex;
      flex-direction: column;
    }

    > div {
      margin-top: 9px;
      margin-bottom: 10px;
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;

      @include mobile {
        margin-top: -40px;
        margin-right: 0;

        // Actual Card
        > div {
          margin: 0 auto;
        }
      }

      @media (max-width: 420px) {
        margin-top: -30px;

        // Actual Card
        > div {
          height: 100px !important;
        }
      }
    }
  }
  &__text {
    font-size: 16px;
    color: #231f20;
  }
}
