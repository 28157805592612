.disclosure {
  &__w {
    max-width: 730px;
    width: 100%;
    margin: 0 auto;
    text-align: left;

    @include mobile {
      margin-top: 20px;
      padding: 0 15px;
    }
    @include tablet {
      padding: 0 15px;
      display: inline-block;
    }
  }

  &__text {
    color: #8a8888;
    font-size: 11px;
    line-height: 16px;
  }
  &__links-w {
    color: #625f60;
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 14px;
    @include mobile {
      padding-bottom: 0;
    }
  }
}
